import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import MintLeosModal from "./modal/mintLeosModal";
import BurnModal from "./modal/burnModal";
import CreateMintModal from "./modal/createMintModal";
import UpdateWhitelistModal from "./modal/updateWhitelistModal";
import ProveModal from "./modal/proveModal";
import TransferCollectionModal from "./modal/transferCollectionModal";
import BuyModal from "./modal/buyModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";

export default function Layout({ children }) {
  const route = useRouter();
  // header start
  const header = <Header01 />;
  // header end

  return (
    <>
      {header}
      <Wallet_modal />
      <BidsModal />
      <MintLeosModal />
      <BurnModal />
      <ProveModal />
      <BuyModal />
      <CreateMintModal />
      <UpdateWhitelistModal />
      <TransferCollectionModal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
