const footerMenuList = [
  {
    id: 1,
    title: "Explore",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/collections",
        text: "Top",
      },
      {
        id: 2,
        href: "/collections",
        text: "All",
      },
      {
        id: 3,
        href: "/collections",
        text: "Minting now",
      },
    ],
  },
  {
    id: 2,
    title: "Create",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/collection/create",
        text: "Create collection",
      },
      {
        id: 2,
        href: "/token/create",
        text: "Create NFT",
      },
    ],
  },
  {
    id: 3,
    title: "My Assets",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/user/",
        text: "Profile",
      }
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://twitter.com/aleo_store",
    text: "twitter",
  },
  {
    id: 2,
    href: "http://discord.gg/VCudeWQYmf",
    text: "discord",
  }
];

export { footerMenuList, socialIcons };
