import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createMintModalHide } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useAlert } from "react-alert";
import { set_collection_mint, is_valid_aleo_address } from "lib/client/aleo";
import { useRouter } from "next/router";


import Image from "next/image";

const loader_img = "/images/loading.gif";


async function setMint(
  create_mint_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  close_modal,
  loading,
  setLoading
) {
  if (loading || !publicKey || !requestRecords || !requestTransaction)
    return
  setLoading(true);
  try {
    throw_invalid_create_mint_data(create_mint_data);
    console.log({ create_mint_data });
    // throw new Error("Not implement on Frontend yet. Call contract for now.");

    const res = await set_collection_mint(
      publicKey,
      requestRecords,
      requestTransaction,
      create_mint_data
    );
    router.push("/transaction/status/leo/" + res);
    document.getElementById("irreversible-check").checked = false;
    close_modal()
    return true;
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }
  setLoading(false);
}


const null_address = "aleo1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq3ljyzc";


function throw_invalid_create_mint_data(create_mint_data) {

  if (create_mint_data.creditsAmount === "") {
    create_mint_data.creditsAmount = 0;
  } else if (create_mint_data.treasuryAddress === "") {
    create_mint_data.treasuryAddress = null_address;
  }

  create_mint_data.creditsAmount =
    Math.round(
      Number(create_mint_data.creditsAmount) * 1_000_000
    );
  if (
    isNaN(create_mint_data.creditsAmount)
    || create_mint_data.creditsAmount < 0
  )
    throw new Error("Please enter a valid amount of credits.");

  if (create_mint_data.endBlock !== "") {
    create_mint_data.endBlock = parseInt(create_mint_data.endBlock);
    if (
      isNaN(create_mint_data.endBlock)
      || create_mint_data.endBlock < 0
    )
      throw new Error("Please enter a valid end block height.");
  } else {
    create_mint_data.endBlock = 0;
  }

  if (create_mint_data.startBlock !== "") {
    create_mint_data.startBlock = parseInt(create_mint_data.startBlock);
    if (
      isNaN(create_mint_data.startBlock)
      || create_mint_data.startBlock < 0
    )
      throw new Error("Please enter a valid start block height.");
  } else {
    create_mint_data.startBlock = 0;
  }

  if (!is_valid_aleo_address(create_mint_data.treasuryAddress))
    throw new Error("Invalid treasury address.");

  return create_mint_data;
}


const CreateMintModal = () => {
  const { createMintModal, create_mint_data } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { publicKey, requestRecords, requestTransaction } = useWallet();
  const alert = useAlert();
  const router = useRouter();

  const [creditsAmount, setCreditsAmount] = useState("");
  const [treasuryAddress, setTreasuryAddress] = useState("");
  const [whitelist, setWhitelist] = useState(false);
  const [random, setRandom] = useState(false);
  const [startBlock, setStartBlock] = useState("");
  const [endBlock, setEndBlock] = useState("");

  const handleEThAmount = (e) => {
    e.preventDefault();
    setCreditsAmount(e.target.value);
  };

  useEffect(() => {
    if (create_mint_data !== undefined) {
      if (create_mint_data.creditsAmount !== undefined)
        setCreditsAmount(create_mint_data.creditsAmount);
      if (create_mint_data.treasuryAddress !== undefined)
        setTreasuryAddress(create_mint_data.treasuryAddress);
      if (create_mint_data.whitelist !== undefined)
        setWhitelist(create_mint_data.whitelist);
      if (create_mint_data.random !== undefined)
        setRandom(create_mint_data.random);
      if (create_mint_data.startBlock !== undefined)
        setStartBlock(create_mint_data.startBlock);
      if (create_mint_data.endBlock !== undefined)
        setEndBlock(create_mint_data.endBlock);
    }
  }, [create_mint_data]);

  const close_modal = () => {
    dispatch(createMintModalHide());
    setCreditsAmount("");
    setTreasuryAddress("");
    setWhitelist(false);
    setRandom(false);
    setStartBlock("");
    setEndBlock("");
  };

  return (
    <div>
      <div className={createMintModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Set Collection Mint
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => close_modal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {/*
                <div className="text-right">
                  <span className="dark:text-jacarta-400 text-sm">
                    Balance: 0.0000 WETH
                  </span>
                </div>
              */}
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white normal-case">
                  Price
                </span>
              </div>
              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
                  <span>
                    <svg className="icon icon-ETH mr-1 h-5 w-5">
                      <use xlinkHref="/icons.svg#icon-domain"></use>
                    </svg>
                  </span>
                  <span className="font-display text-jacarta-700 text-sm">
                    Credits
                  </span>
                </div>
                <input
                  type="number"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="Amount"
                  value={creditsAmount}
                  onChange={(e) => handleEThAmount(e)}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="treasury-address"
                  className="font-display text-jacarta-700 text-sm font-semibold dark:text-white normal-case"
                >
                  Treasury address
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="url"
                    id="treasury-address"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white flex-grow"
                    placeholder="aleo1..."
                    value={treasuryAddress}
                    onChange={(e) => setTreasuryAddress(e.target.value)}
                  />
                  <button
                    onClick={
                      () => {
                        setTreasuryAddress(publicKey)
                      }
                    }
                    className="group dark:bg-jacarta-700 hover:bg-accent border-accent flex h-12 w-12 shrink-0 items-center justify-center rounded-lg border bg-white hover:border-transparent">
                    <span className="text-accent text-2xs font-semibold group-hover:text-white">
                      Use mine
                    </span>
                  </button>
                </div>
              </div>

              <div className="mb-6">
                <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
                  Whitelist
                </label>
                <input
                  type="checkbox"
                  value="checkbox"
                  name="check"
                  id="collection-whitelist"
                  className="checked:bg-accent checked:focus:bg-accent checked:hover:bg-accent after:bg-jacarta-400 bg-jacarta-100 relative h-6 w-[2.625rem] cursor-pointer appearance-none rounded-full border-none after:absolute after:top-[0.1875rem] after:left-[0.1875rem] after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:transition-all checked:bg-none checked:after:left-[1.3125rem] checked:after:bg-white focus:ring-transparent focus:ring-offset-0 float-left block m-[5px]"
                  checked={whitelist}
                  onChange={(e) => setWhitelist(e.target.checked)}
                />
                <label className="dark:text-white ml-2 text-sm block cursor-pointer  mt-[12px]" htmlFor="collection-whitelist">
                  Restrict addresses which can mint
                </label>
              </div>
              <div className="mb-6">
                <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
                  Random
                </label>
                <input
                  type="checkbox"
                  value="checkbox"
                  name="check"
                  id="random-mint"
                  className="checked:bg-accent checked:focus:bg-accent checked:hover:bg-accent after:bg-jacarta-400 bg-jacarta-100 relative h-6 w-[2.625rem] cursor-pointer appearance-none rounded-full border-none after:absolute after:top-[0.1875rem] after:left-[0.1875rem] after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:transition-all checked:bg-none checked:after:left-[1.3125rem] checked:after:bg-white focus:ring-transparent focus:ring-offset-0 float-left block m-[5px]"
                  checked={random}
                  onChange={(e) => setRandom(e.target.checked)}
                />
                <label className="dark:text-white ml-2 text-sm block cursor-pointer  mt-[12px]" htmlFor="random-mint">
                  NFTs are distributed randomly
                </label>
              </div>
              {/* <!-- Begin and end number inputs on the same line  --> */}
              <div className="">
                <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
                  Period (empty or 0 for no limit)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    id="begin-block"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white flex-grow"
                    placeholder="Start Block Height"
                    value={startBlock}
                    onChange={(e) => setStartBlock(e.target.value)}
                  />
                  <span className="text-jacarta-400 text-sm">to</span>
                  <input
                    type="number"
                    id="end-block"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white flex-grow"
                    placeholder="End Block Height"
                    value={endBlock}
                    onChange={(e) => setEndBlock(e.target.value)}
                  />
                </div>
              </div>


              {/* <!-- Terms --> */}

            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={
                    () => setMint(
                      {
                        creditsAmount,
                        treasuryAddress,
                        whitelist,
                        random,
                        startBlock,
                        endBlock,
                        collection: create_mint_data.collection._record
                      },
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      close_modal,
                      loading,
                      setLoading
                    )}
                >
                  {
                    loading
                      ? <Image src={loader_img} width={25} height={25} />
                      : "Set Mint"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMintModal;
