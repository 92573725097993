import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { proveModalHide } from "../../redux/counterSlice";
import { useAlert } from "react-alert";
import { prove_collection_ownership, prove_token_ownership, prove_collection_holdership } from "../../lib/client/aleo";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useRouter } from 'next/router';



async function proveToken(
  prove_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  address,
  setAddress,
  close_modal
) {
  try {
    if (!publicKey || !requestRecords || !requestTransaction)
      throw new Error("Wallet not connected");

    let prove_function = null;
    let height = await fetch(
      `${process.env.NEXT_PUBLIC_ALEO_EXPLORER_API_URL}/latest/height`
    ).then(res => res.json());
    if (prove_data.type == "collection_ownership")
      prove_function = prove_collection_ownership;
    else if (prove_data.type == "token_ownership")
      prove_function = prove_token_ownership;
    else if (prove_data.type == "collection_holdership")
      prove_function = prove_collection_holdership;

    console.log(height)
    const res = await prove_function(
      publicKey,
      requestRecords,
      requestTransaction,
      {
        ...prove_data,
        address,
        height
      }
    );

    router.push("/transaction/status/leo/" + res);
    setAddress("");
    close_modal()
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }

}


const ProveModal = () => {
  const { proveModal, prove_data } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const alert = useAlert();
  const router = useRouter();
  const {
    publicKey,
    requestRecords,
    requestTransaction
  } = useWallet();

  const [address, setAddress] = useState("");

  const handleAddress = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
  };
  const close_modal = () => (dispatch(proveModalHide()));
  return (
    <div>
      <div className={proveModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Prove
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={close_modal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  To Address
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">

                <input
                  type="text"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="aleo1..."
                  value={address}
                  onChange={(e) => handleAddress(e)}
                />

              </div>
              {/*
              <div className="text-right">
                <span className="dark:text-jacarta-400 text-sm">
                  Balance: 0.0000 WETH
                </span>
  </div>*/}
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={() =>
                    proveToken(
                      prove_data,
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      address,
                      setAddress,
                      close_modal
                    )
                  }
                >
                  Send Proof
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ProveModal;
