import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bidsModalHide } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useAlert } from "react-alert";
import { create_listing, update_listing } from "lib/client/aleo";
import { useRouter } from "next/router";

import Image from "next/image";


const loader_img = "/images/loading.gif";



async function listToken(
  publicKey,
  requestRecords,
  requestTransaction,
  price,
  token_hex_id,
  is_update,
  router,
  alert,
  loading,
  setLoading,
  setCreditsAmount,
  dispatch
) {
  if (loading || !price || !publicKey || price <= 0) return;
  setLoading(true);
  try {
    const token_number_hex = token_hex_id.slice(0, 32);
    const collection_number_hex = token_hex_id.slice(32, 64);
    const token_id = {
      token_number: BigInt("0x" + collection_number_hex),
      collection_number: BigInt("0x" + token_number_hex),
    };
    const to_call = is_update ? update_listing : create_listing;
    const res = await to_call(
      publicKey, requestRecords, requestTransaction, token_id, price
    );

    dispatch(bidsModalHide());
    setCreditsAmount("");
    alert.success("Transaction in progress.");
    router.push("/transaction/status/leo/" + res);

  } catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
    console.log(err_str);
  }
  setLoading(false);
}


const BidsModal = () => {
  const { bidsModal, bidsModalData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [creditsAmount, setCreditsAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const { publicKey, requestRecords, requestTransaction } = useWallet();
  const alert = useAlert();
  const router = useRouter();


  const handleEThAmount = (e) => {
    e.preventDefault();
    setCreditsAmount(e.target.value);
  };
  const marketplaceFees = Number(
    (Number(creditsAmount) * process.env.NEXT_PUBLIC_MARKET_FEE / 1e4)
      .toFixed(6)
  );
  const royaltyFees = Number(
    (Number(creditsAmount) * bidsModalData.royalty_fees / 1e4)
      .toFixed(6)
  );
  const receivedLeos = Number(
    (Number(creditsAmount) - marketplaceFees - royaltyFees)
      .toFixed(6)
  );
  const buyerPay = Number((Number(creditsAmount)).toFixed(6));

  return (
    <div>
      <div className={bidsModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                {bidsModalData.is_update ? "Update price" : "List NFT"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setCreditsAmount("");
                  dispatch(bidsModalHide())
                }
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white normal-case">
                  Price
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
                  <span>
                    <svg className="icon icon-ETH mr-1 h-5 w-5">
                      <use xlinkHref="/icons.svg#icon-domain"></use>
                    </svg>
                  </span>
                  <span className="font-display text-jacarta-700 text-sm">
                    Credits
                  </span>
                </div>

                <input
                  type="number"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="Amount"
                  value={creditsAmount}
                  onChange={(e) => handleEThAmount(e)}
                />

              </div>
              {/*
              <div className="text-right">
                <span className="dark:text-jacarta-400 text-sm">
                  Balance: 0.0000 WETH
                </span>
  </div>*/}
              {/* <!-- Terms --> */}

              <div className="mt-4 flex items-center space-x-2">
                {receivedLeos <= 0 ? (
                  <label
                    className="dark:text-jacarta-200 text-sm text-red font-semibold"
                  >
                    A higher amount is required.
                  </label>)
                  : (
                    <div style={{ width: "100%" }}>
                      <div
                        className="dark:text-jacarta-200 text-sm flex flex-line space-x-1"
                      >
                        <div
                          className="dark:text-jacarta-200 text-sm"
                        >
                          Buyer will pay:
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <strong>{String(buyerPay)}</strong> Credits
                        </div>
                      </div>
                      <div
                        className="dark:text-jacarta-200 text-sm flex flex-line space-x-1"
                      >
                        <div
                          className="dark:text-jacarta-200 text-sm"
                        >
                          Creator Royalty fees <strong>({String((bidsModalData.royalty_fees / 1e2).toFixed(2))}%)</strong>:
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <strong>-{String(royaltyFees)}</strong> Credits
                        </div>
                      </div>
                      <div
                        className="dark:text-jacarta-200 text-sm flex flex-line space-x-1"
                      >
                        <div
                          className="dark:text-jacarta-200 text-sm"
                        >
                          Marketplace fee <strong>({String((process.env.NEXT_PUBLIC_MARKET_FEE / 1e2).toFixed(2))}%)</strong>:
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <strong>-{String(marketplaceFees)}</strong> Credits
                        </div>
                      </div>
                      <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-2 h-px border-0 block" style={{ width: "100%" }} />
                      <div
                        className="dark:text-jacarta-200 text-sm flex flex-line space-x-1"
                      >
                        <div
                          className="dark:text-jacarta-200 text-sm"
                        >
                          You will receive:
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <strong>{String
                            (receivedLeos)}</strong> Credits
                        </div>
                      </div>
                    </div>
                  )}

              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  style={{ cursor: receivedLeos <= 0 ? "not-allowed" : "pointer" }}
                  onClick={
                    () => {
                      if (receivedLeos <= 0) return;
                      listToken(
                        publicKey,
                        requestRecords,
                        requestTransaction,
                        buyerPay * 1e6,
                        bidsModalData.token_id,
                        bidsModalData.is_update,
                        router,
                        alert,
                        loading,
                        setLoading,
                        setCreditsAmount,
                        dispatch,
                      )
                    }
                  }
                >
                  {
                    loading
                      ? <Image src={loader_img} width={25} height={25} />
                      : (bidsModalData.is_update ? "Update listing" : "List for sale")
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default BidsModal;
