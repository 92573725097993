import {
  hamp_struct_to_js_object,
  decode_PublicCollectionData,
  encode_TokenId, decode_Token,
  snarkos_struct_to_js_object,
  encode_CollectionId,
  decode_CollectionPublicData,
  decode_str_from_int,
  js_string_to_usize,
  decode_str_from_int_leading,
} from "./types";
import { get_user_collections, accept_listing, cancel_listing } from "./contract_calls";



export function loadUriData(uri, default_uri) {
  if (!default_uri)
    default_uri = "";
  if (uri.startsWith("ipfs://"))
    uri = process.env.NEXT_PUBLIC_IPFS_GATEAWAY + "/ipfs/" + uri.slice(7);
  if (uri.length === 0)
    return default_uri;
  return uri;
}



export async function loadCollectionData(collection) {
  collection.public_data = await get_public_collection_data(collection.id);
  return collection;
}


export async function loadTokensData(tokens) {
  const collection_hex_ids = new Set();
  for (const token of tokens) {
    collection_hex_ids.add(token.id.collection_number.toString(16).padStart(32, '0'));
  }

  const collections = await Promise.all(
    [...collection_hex_ids].map(get_public_collection)
  );
  const collection_id_to_collection = {};
  for (const collection of collections) {
    collection_id_to_collection[collection.id.collection_number] = collection;
  }
  for (const token of tokens) {
    token.collection = collection_id_to_collection[token.id.collection_number];
  }
  return await Promise.all(
    tokens.map(loadTokenData)
  );
}


export async function loadTokenData(token) {
  if (token.id.collection_number.toString() === "0") {
    const metadata = JSON.parse(JSON.stringify(alias_token_metadata));
    metadata.name = decode_str_from_int_leading(token.id.token_number);
    return {
      ...token,
      metadata,
      metadata_uri: ""
    };
  }
  console.log("a")
  const metadata_uri = loadUriData(token.collection.public_data.base_uri + token.data.metadata_uri);
  let metadata = JSON.parse(JSON.stringify(default_token_metadata));
  console.log("b")
  try {
    metadata = await fetch(metadata_uri).then(res => res.json());
  } catch (e) {
    console.log(e);
  }
  console.log("c")

  metadata.image = loadUriData(metadata.image, "/images/create_collection/user_avatar.png");

  console.log("d")
  return {
    ...token,
    metadata,
    metadata_uri
  };
}


export async function loadTokensMintData(tokenMints, collection, collection_mint) {
  const decoded_tokens = tokenMints.map(
    (tokenMint) => {
      const decoded = decode_Token(tokenMint);
      decoded.collection = collection;
      decoded.collection_mint = collection_mint;
      return decoded;
    }
  );
  return await Promise.all(
    decoded_tokens.map(loadTokenData)
  );
}




export async function loadPrivacyPridesData(tokens) {
  return await Promise.all(
    tokens.map(loadPrivacyPrideData)
  );
}


export async function loadPrivacyPrideData(token) {
  const metadata_uri = loadUriData(
    "https://aleo-public.s3.us-west-2.amazonaws.com/testnet3/" + token.data
  );
  const metadata = { image: metadata_uri.replace("json", "png") };
  const name = "Privacy Pride #" + token.data.split("/")[1].split(".")[0];

  return {
    ...token,
    metadata,
    metadata_uri,
    name
  };
}
/*
https://explorer.hamp.app/api/v1/mapping/list_program_mapping_values/{program_id}/{mapping}
also,
/api/v1/mapping/list_program_mapget_public_collection_dataam_id}/{mapping}/{key}
*/


export async function get_public_collection_data(collection_id) {
  if (collection_id.collection_number.toString() === "0") {
    return JSON.parse(JSON.stringify(alias_collection_public_data));
  }
  let collection_public_data = await get_mapping_value(
    "collectionPublicData",
    encode_CollectionId(collection_id)
  );
  collection_public_data = decode_CollectionPublicData(hamp_struct_to_js_object(collection_public_data));

  return await load_collection_public_data(collection_public_data);
}

export const DEFAULT_COLLECTION_ICON_PATH = "/images/create_collection/user_avatar.png";
export const DEFAULT_COLLECTION_COVER_PATH = "/images/create_collection/banner.png";

export async function load_collection_public_data(collection_public_data) {
  const metadata_uri = loadUriData(collection_public_data.metadata_uri);

  let metadata = JSON.parse(JSON.stringify(default_collection_metadata));
  try {
    metadata = await fetch(metadata_uri).then(res => res.json());
  } catch (e) { }


  metadata.icon_url = loadUriData(metadata.icon_url, DEFAULT_COLLECTION_ICON_PATH);
  metadata.cover_url = loadUriData(metadata.cover_url, DEFAULT_COLLECTION_COVER_PATH);
  collection_public_data = {
    ...collection_public_data,
    metadata,
    metadata_uri,
  }
  return collection_public_data;
}


export async function get_public_collection(collection_hex_id) {
  const collection_id = { collection_number: BigInt("0x" + collection_hex_id) };

  const collection_public_data = await get_public_collection_data(collection_id);
  const collection = {
    id: collection_id,
    hex_id: collection_hex_id,
    public_data: collection_public_data,
  };
  return collection
}


export async function get_collection_market_data(collection_hex_id) {
  const collection_id = { collection_number: BigInt("0x" + collection_hex_id) };
  const collection_identifier = "collection_number: " + collection_id.collection_number.toString() + "u128}";

  let [
    tokenExists,
    publicTokenOwners,
    listings
  ] = await Promise.all([
    get_mapping_entries("tokenExists"),
    get_mapping_entries("publicTokenOwners"),
    get_mapping_entries("listings")
  ]);
  const total_supply = tokenExists.filter((entry) =>
    entry.key.endsWith(collection_identifier)
  ).length;

  const public_token_owned = publicTokenOwners.filter((entry) =>
    entry.key.endsWith(collection_identifier)
  );

  const public_token_owners = public_token_owned.map((entry) =>
    entry.value
  );

  const listing_amount = listings.filter((entry) =>
    entry.key.endsWith(collection_identifier)
  ).length;


  return {
    listing_amount: listing_amount,
    total_supply: total_supply,
    public_supply: public_token_owned.length + listing_amount,
    private_supply: total_supply - public_token_owned.length - listing_amount,
    public_token_owners: public_token_owners.length
  };
}


export async function get_public_token(token_id) {
  const [token_owner, token_data] = await Promise.all([
    get_public_token_owner(token_id),
    get_public_token_data(token_id)
  ]);
  if (!token_data)
    return null;

  const public_collection_data_struct_js = {
    owner: token_owner,
    data: token_data,
    id: token_id
  };

  return decode_Token(public_collection_data_struct_js);
}

export async function get_public_tokens_owned_by(address) {
  const publicTokenOwners_entries = await get_mapping_entries("publicTokenOwners");
  if (!publicTokenOwners_entries || publicTokenOwners_entries.error)
    return [];
  const public_tokens = await Promise.all(publicTokenOwners_entries.filter((entry) => {
    return entry.value.replace(/\s/g, '') === address
  }).map(
    async (entry) => {
      const token_id = hamp_struct_to_js_object(entry.key);
      const token_data = await get_public_token_data(token_id);
      return decode_Token({
        owner: entry.value,
        data: token_data,
        id: token_id
      })
    }));

  return public_tokens;
}



export async function get_public_token_data(token_id) {
  const mapping_key = encode_TokenId(token_id);
  const rep = await get_mapping_value("publicTokenData", mapping_key)
  if (!rep)
    return null;
  return hamp_struct_to_js_object(rep);
}

export async function get_public_token_owner(token_id) {
  const mapping_key = encode_TokenId(token_id);
  return await get_mapping_value("publicTokenOwners", mapping_key);
}


export async function get_mapping_value(mapping_name, key) {
  key = key.replace(/\s/g, '')

  const api_url = (
    `${process.env.NEXT_PUBLIC_HAMP_EXPLORER_API_URL}/mapping/get_value/`
    + `${process.env.NEXT_PUBLIC_NFT_PROGRAM_ID}/${mapping_name}/${key}?outdated=1`
  );

  const mapping_entry = await fetch(
    api_url
  ).then(res => res.json());
  return mapping_entry;
}

export async function get_mapping_entries(mapping_name) {
  const api_url = (
    `${process.env.NEXT_PUBLIC_HAMP_EXPLORER_API_URL}/mapping/list_program_mapping_values/`
    + `${process.env.NEXT_PUBLIC_NFT_PROGRAM_ID}/${mapping_name}?outdated=1`
  );
  const mapping_entries = await fetch(
    api_url
  ).then(res => res.json());
  return mapping_entries ? Object.values(mapping_entries) : [];
}


export async function get_collection_all_data(collection_hex_id, publicKey, requestRecords) {
  const collections_record_data = await get_user_collections(publicKey, requestRecords);
  let collection_data = (
    collections_record_data.filter((collection) => {
      return collection_hex_id === collection.hex_id
    })
  )[0];
  if (!collection_data)
    return null
  const collection = await loadCollectionData(collection_data);

  return collection;
}


export async function get_collection_mints(
  collection_id
) {
  const entries = await get_mapping_entries("collectionMintData");

  const mints = entries.map((item) => {
    const mint_id = hamp_struct_to_js_object(item.key);
    const mint_hex_id = (
      mint_id.collection_number.toString(16).padStart(32, '0')
      + mint_id.mint_number.toString(16).padStart(32, '0')
    );
    const mint_data = hamp_struct_to_js_object(item.value);

    return {
      id: mint_id,
      hex_id: mint_hex_id,
      mint_data
    };
  });
  const collection_mints = mints.filter(
    (item) => item.hex_id.startsWith(collection_id)
  );

  return collection_mints;
}

export async function get_token_mint_index(
  mint_id
) {
  const entries = await get_mapping_entries("mintTokenNumbers");
  for (const entry of entries) {
    const token_number = BigInt(entry.value.slice(0, entry.value.length - 4));
    const index_collection_mint_id = hamp_struct_to_js_object(entry.key);
    if (
      index_collection_mint_id.collection_number === mint_id.collection_number
      && index_collection_mint_id.mint_number === mint_id.mint_number
      && token_number === mint_id.token_number
    )
      return index_collection_mint_id.index;
  }
  return null;
}

export async function get_collection_mint_whitelist(
  collection_mint_id
) {
  const entries = await get_mapping_entries("mintWhitelists");

  const all_whitelists = entries.map((item) => {
    const id = hamp_struct_to_js_object(item.key);
    const amount = Number(item.value.slice(0, item.value.length - 3));

    return {
      id,
      amount
    };
  });
  const whitelists = all_whitelists.filter(
    (item) => (
      item.id.collection_number === collection_mint_id.collection_number
      && item.id.mint_number === collection_mint_id.mint_number
      && item.amount > 0
    )
  );

  return whitelists;
}



export async function get_token_mints(
  mint_id
) {
  const collection_hex_number = mint_id.slice(0, 32);
  const mint_number = mint_id.slice(32, 64);

  const [
    tokenMintData_entries,
    tokenExists_entry
  ] = await Promise.all([
    get_mapping_entries("tokenMintData"),
    get_mapping_entries("tokenExists"),
  ]);

  const tokenMintDatas = tokenMintData_entries.map((item) => {
    const token_id = hamp_struct_to_js_object(item.key);
    const token_hex_id = (
      token_id.collection_number.toString(16).padStart(32, '0')
      + token_id.token_number.toString(16).padStart(32, '0')
    );
    const token_data = hamp_struct_to_js_object(item.value);

    return {
      id: token_id,
      hex_id: token_hex_id,
      data: token_data
    };
  });

  const token_hex_ids = new Set(
    tokenExists_entry.map((item) => {
      const token_id = hamp_struct_to_js_object(item.key);
      const token_hex_id = (
        token_id.collection_number.toString(16).padStart(32, '0')
        + token_id.token_number.toString(16).padStart(32, '0')
      );
      return token_hex_id;
    })
  );
  const token_mints = tokenMintDatas.filter(
    (item) => (
      item.id.collection_number.toString(16).padStart(32, '0') === collection_hex_number
      && item.id.mint_number.toString(16).padStart(32, '0') === mint_number
      && !token_hex_ids.has(item.hex_id)
    )
  );

  return token_mints;
}

export async function get_tokens_listed_by(address) {
  const publicTokenListing_entries = await get_mapping_entries("listings");
  if (!publicTokenListing_entries || publicTokenListing_entries.error)
    return [];
  const public_tokens = (await Promise.all(publicTokenListing_entries.filter((entry) => {
    return entry.value.startsWith("{seller: " + address)
  }).map(
    async (entry) => {
      const token_id = hamp_struct_to_js_object(entry.key);
      const token_data = await get_public_token_data(token_id);
      const listing = hamp_struct_to_js_object(entry.value);
      return {
        token: {
          ...decode_Token({
            seller: address,
            data: token_data,
            id: token_id
          }),
        },
        price: listing.price,
        seller: address,
      }
    }))).filter((listing) => {
      return listing.seller !== "aleo1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq3ljyzc";
    });

  return public_tokens;
}


export async function get_listing(token_id) {
  const mapping_key = encode_TokenId(token_id);
  const rep = await get_mapping_value("listings", mapping_key)
  if (!rep)
    return null;
  return hamp_struct_to_js_object(rep);
}


export async function get_token_owner(token_id) {
  const mapping_key = encode_TokenId(token_id);
  const rep = await get_mapping_value("publicTokenOwners", mapping_key)
  if (!rep)
    return null;

  return hamp_struct_to_js_object(rep);
}


export async function get_alias_owner(alias) {
  const token_number = js_string_to_usize(alias, 128);
  const token_id = { token_number, collection_number: 0 };
  const mapping_key = encode_TokenId(token_id);
  const [alias_owner, alias_owned_rep] = await Promise.all([
    get_token_owner(token_id),
    get_mapping_value("tokenExists", mapping_key)
  ]);
  let alias_owned = Boolean(alias_owned_rep);

  return { alias_owned, alias_owner };
}


export async function token_exists(token_id) {
  const mapping_key = encode_TokenId(token_id);
  const token_exists = await get_mapping_value("tokenExists", mapping_key);

  return Boolean(token_exists);
}


export async function get_tokens_listed_collection(collection_hex_id) {
  const collection_number_end = "collection_number: " + BigInt("0x" + collection_hex_id).toString() + "u128}";
  const publicTokenListing_entries = await get_mapping_entries("listings");
  if (!publicTokenListing_entries || publicTokenListing_entries.error)
    return [];
  const public_tokens = (await Promise.all(publicTokenListing_entries.filter((entry) => {
    return entry.key.endsWith(collection_number_end)
  }).map(
    async (entry) => {
      const token_id = hamp_struct_to_js_object(entry.key);
      const token_data = await get_public_token_data(token_id);
      const listing = hamp_struct_to_js_object(entry.value);
      return {
        token: {
          ...decode_Token({
            data: token_data,
            id: token_id
          }),
        },
        price: listing.price,
        seller: listing.seller,
      }
    })));
  return public_tokens;
}


export async function get_all_tokens_collection(collection_hex_id) {
  const collection_number_end = "collection_number: " + BigInt("0x" + collection_hex_id).toString() + "u128}";
  const publicTokenListing_entries = await get_mapping_entries("publicTokenData");
  if (!publicTokenListing_entries || publicTokenListing_entries.error)
    return [];

  const public_tokens = (await Promise.all(publicTokenListing_entries.filter((entry) => {
    return entry.key.endsWith(collection_number_end)
  }).map(
    async (entry) => {
      const token_id = hamp_struct_to_js_object(entry.key);
      const token_data = hamp_struct_to_js_object(entry.value);
      return {
        token: {
          ...decode_Token({
            data: token_data,
            id: token_id
          }),
        },
      }
    })));
  return public_tokens;
}





export async function buyListing(item, alert, loading, setLoading, publicKey, requestRecords, requestTransaction, router) {
  if (loading || !item) return;
  setLoading(true);
  try {
    if (!publicKey)
      throw new Error("Please connect your wallet first.");
    const accept_listing_data = {
      listing: {
        seller: item.seller,
        price: item.price,
      },
      royalty_fees: item.collection.public_data.royalty_fees,
      royalty_address: item.collection.public_data.royalty_address,
      token_id: item.id,
      token_data: {
        _metadata_uri: item.data._metadata_uri,
        transferable: item.data.transferable,
      }
    };

    const res = await accept_listing(
      publicKey, requestRecords, requestTransaction, accept_listing_data
    );
    alert.success("Transaction in progress.");
    router.push("/transaction/status/leo/" + res);

  } catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
    console.log(err_str);
  }
  setLoading(false);
}


export async function cancelListing(item, alert, loading, setLoading, publicKey, requestRecords, requestTransaction, router) {
  if (loading || !publicKey || !item) return;
  setLoading(true);
  try {
    const res = await cancel_listing(
      publicKey, requestRecords, requestTransaction, item.id
    );
    alert.success("Transaction in progress.");
    router.push("/transaction/status/leo/" + res);

  } catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
    console.log(err_str);
  }
  setLoading(false);
}

export async function get_all_collections(page, amountPerPage) {
  if (page === undefined)
    page = 1;
  if (amountPerPage === undefined)
    amountPerPage = 12;
  const collectionPublicData_entries = await get_mapping_entries("collectionPublicData");
  if (!collectionPublicData_entries || collectionPublicData_entries.error)
    return [];

  const collection_amount = collectionPublicData_entries.length;
  console.log(collectionPublicData_entries)
  const collections = await Promise.all(
    collectionPublicData_entries
      .slice(
        (page - 1) * amountPerPage, page * amountPerPage)
      .map(async (entry, i) => {
        const id = hamp_struct_to_js_object(entry.key);
        const collection_public_data = decode_CollectionPublicData(
          hamp_struct_to_js_object(
            entry.value
          )
        );
        let public_data = null;
        if (id.collection_number.toString() !== "0") {
          public_data = await load_collection_public_data(
            collection_public_data
          );
        } else {
          public_data = JSON.parse(JSON.stringify(alias_collection_public_data));
        }
        return {
          id,
          index: (page - 1) * amountPerPage + i,
          hex_id: id.collection_number.toString(16).padStart(32, '0'),
          public_data
        }
      })
  );

  console.log(collections)
  return {
    collection_amount,
    collections,
    pages: Math.ceil(collection_amount / amountPerPage),
  };
}


const default_collection_metadata = {
  icon_url: '',
  cover_url: '',
  name: 'Unknown',
  socials: {
    twitter: '',
    discord: '',
    website: '',
  },
  ticker: '?',
  description: '',
  base_uri: '',
};


const default_token_metadata = {
  name: 'Unknown',
  description: '',
  image: '',
  external_url: '',
  attributes: [],
};

const alias_token_metadata = {
  name: 'Alias',
  description: 'Alias are readable names pointing to a wallet address. They are unique and can be used in wallets and dApps to transfer credits and tokens.',
  image: '/images/icon_alias.png',
  external_url: 'https://aleo.store/alias',
  attributes: [],
}


const alias_collection_public_data = {
  royalty_fees: 0,
  royalty_address: 'aleo1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq3ljyzc',
  metadata_uri: '',
  metadata: {
    icon_url: '/images/icon_alias.png',
    cover_url: '/images/banner_alias.png',
    name: 'Alias',
    socials: {
      twitter: '',
      discord: '',
      website: '',
    },
    ticker: 'AT',
    description: 'Alias are readable names pointing to a wallet address. They are unique and can be used in wallets and dApps to transfer credits and tokens.',
    base_uri: '',
  },
  base_uri: '',
  publicizable: true
}
