

import JSONbig from 'json-bigint';
const JSONBIG = JSONbig({ useNativeBigInt: true });

function js_string_to_bytes(js_string) {
  const bytes = [];
  for (let i = 0; i < js_string.length; i++) {
    const charCode = js_string.charCodeAt(i);
    bytes.push(charCode & 0xFF); // Extract the lower 8 bits
  }
  return bytes;
}

function bytes_to_chunks(bytes, aleo_string_parts_amount, aleo_unsigned_int_bits) {
  const chunks = [];
  const bytes_per_part = Math.floor(aleo_unsigned_int_bits / 8);
  while (bytes.length > 0)
    chunks.push(bytes.splice(0, bytes_per_part));

  if (chunks.length && chunks.at(-1).length < aleo_unsigned_int_bits) {
    for (let i = chunks.at(-1).length; i <= bytes_per_part - 1; i++)
      chunks.at(-1).push(0)
  }
  while (chunks.length < aleo_string_parts_amount) {
    chunks.push([])
    for (let i = 0; i <= bytes_per_part - 1; i++)
      chunks.at(-1).push(0)
  }
  return chunks;
}

function chunk_to_big_int(
  uint8_bytes_array,
  aleo_unsigned_int_bits
) {
  let bigInt = BigInt(0);
  const bytes_per_aleo_unsigned_int_bytes = Math.floor(aleo_unsigned_int_bits / 8);
  for (let i = 0; i < bytes_per_aleo_unsigned_int_bytes; i++) {
    bigInt = (bigInt << BigInt(8)) | BigInt(uint8_bytes_array[i]);
  }

  return bigInt;
}

export function usize_to_string(usize, bits) {
  usize.toString(16).padStart(bits / 8, 0)
}

function big_ints_to_aleo_string_struct(
  big_ints,
  aleo_unsigned_int_bits
) {
  const aleo_uints = big_ints.map(
    (big_int, i) => (
      `part${i}:${encode_unsigned_int(big_int, aleo_unsigned_int_bits)}`
    )
  );
  return "{" + aleo_uints.join(",") + "}";
}

export function js_string_to_aleo_string(js_string, aleo_string_parts_amount, aleo_unsigned_int_bits) {
  const bytes = js_string_to_bytes(js_string);

  const total_bits = aleo_string_parts_amount * aleo_unsigned_int_bits;
  if (bytes.length * 8 > total_bits) {
    throw ("Input string is too long to be encoded.");
  }
  const chunks = bytes_to_chunks(
    bytes, aleo_string_parts_amount, aleo_unsigned_int_bits
  );
  const big_ints = chunks.map(
    (chunk) => chunk_to_big_int(
      chunk, aleo_unsigned_int_bits
    )
  );
  return big_ints_to_aleo_string_struct(big_ints, aleo_unsigned_int_bits);
}

export function js_string_to_usize(js_string, aleo_unsigned_int_bits) {
  const bytes = js_string_to_bytes(js_string);
  if (bytes.length * 8 > aleo_unsigned_int_bits) {
    throw ("Input string is too long to be encoded.");
  }
  while (bytes.length < Math.floor(aleo_unsigned_int_bits / 8)) {
    bytes.push(0)
  }

  return chunk_to_big_int(bytes, aleo_unsigned_int_bits)
}

export function encode_unsigned_int(
  unsigned_int,
  aleo_unsigned_int_bits
) {
  return unsigned_int.toString() + "u" + aleo_unsigned_int_bits.toString();
}

export function encode_bool(
  value,
) {
  return String(value)
}

export function random_uint(
  aleo_unsigned_int_bits
) {
  const array = new Uint8Array(aleo_unsigned_int_bits / 8);
  window.crypto.getRandomValues(array);
  return chunk_to_big_int(array, 128);
}


export function encode_CollectionMetadata({
  name,
  ticker,
  base_uri,
}) {
  const encoded_name = js_string_to_aleo_string(name, 4, 128);
  const encoded_ticker = js_string_to_aleo_string(ticker, 1, 64);
  const encoded_base_uri = js_string_to_aleo_string(base_uri, 4, 128);
  return (
    "{name:" + encoded_name
    + ",ticker:" + encoded_ticker
    + ",base_uri:" + encoded_base_uri
    + "}"
  );
}


export function snarkos_records_to_js_object(snarkos_records) {
  return snarkos_records.map(
    snarkos_record =>
      snarkos_struct_to_js_object(snarkos_record)
  );
}

function replaceBetween(str, start, end, what) {
  return str.substring(0, start) + what + str.substring(end);
};

function aleo_object_to_js(aleo_obj) {

  const intPattern = /(\-*\d+)(i|u)(\d+)/;

  const matches_int = aleo_obj.match(intPattern);
  if (matches_int) {
    const [, number1, type, number2] = matches_int;
    return BigInt(number1, 10).toString();
  }
  if (aleo_obj === "true") return "true"
  if (aleo_obj === "false") return "false"

  return '"' + String(aleo_obj) + '"'
}

export function snarkos_struct_to_js_object(snarkos_struct) {
  let snarkos_struct_r = snarkos_struct.replace(/\s/g, '');
  let regexp = /\-*[0-9a-z]*\.(private|public)/g;

  let matches = [...snarkos_struct_r.matchAll(regexp)];
  let match_indexes = matches.map((match) => {
    const i_beg = match.index;
    const i_end = match.index + match[0].length;
    return ([i_beg, i_end, match[0]])
  });
  match_indexes.sort((a, b) => b[0] - a[0])
  for (const [beg, end, match] of match_indexes) {
    let rep = match.split(".")[0];

    snarkos_struct_r = replaceBetween(snarkos_struct_r, beg, end, aleo_object_to_js(rep))
  }
  const json_record = snarkos_struct_r.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');

  const parsed = JSONBIG.parse(json_record);

  parsed._original_record = snarkos_struct;
  return parsed;
}


export function hamp_struct_to_js_object(snarkos_struct) {
  if (snarkos_struct.startsWith("aleo1"))
    return snarkos_struct;
  let snarkos_struct_r = snarkos_struct.replace(/\s/g, '');
  let regexp = /(\:|\ )[\-a-zA-Z0-9]+(\,|\ |\})/g;

  let matches = [...snarkos_struct_r.matchAll(regexp)];
  let match_indexes = matches.map((match) => {
    const i_beg = match.index + 1;
    const i_end = match.index + match[0].length - 1;
    return ([i_beg, i_end, match[0].slice(1, match[0].length - 1)])
  });
  match_indexes.sort((a, b) => b[0] - a[0])
  for (const [beg, end, match] of match_indexes) {
    let rep = match.split(".")[0];

    snarkos_struct_r = replaceBetween(snarkos_struct_r, beg, end, aleo_object_to_js(rep))
  }
  const json_record = snarkos_struct_r.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');


  const parsed = JSONBIG.parse(json_record);

  delete parsed['_nonce'];

  return parsed;
}




function aleo_string_obj_to_js_string(aleo_string) {
  let out_str = "";
  for (const part in aleo_string) {
    out_str += decode_str_from_int(aleo_string[part]);
  }
  return out_str;
}

function bnToBuf(bn) {
  var hex = bn.toString(16).padStart(32, '0');
  if (hex.length % 2) { hex = '0' + hex; }

  var len = hex.length / 2;
  var u8 = new Uint8Array(len);

  var i = 0;
  var j = 0;
  while (i < len) {
    u8[i] = parseInt(hex.slice(j, j + 2), 16);
    i += 1;
    j += 2;
  }
  return u8;
}

function removeNullBytes(str) {
  return str.split("").filter(char => char.codePointAt(0)).join("")
}

export function decode_str_from_int_leading(inpt_int) {
  const buffarr = bnToBuf(inpt_int);

  let decoded_str = new TextDecoder().decode(buffarr)
  while (decoded_str.endsWith("\u0000")) {
    decoded_str = decoded_str.slice(0, decoded_str.length - 1)
  }
  return decoded_str;
}

export function decode_str_from_int(inpt_int) {
  const buffarr = bnToBuf(inpt_int);

  let decoded_str = new TextDecoder().decode(buffarr)
  while (decoded_str.endsWith("\u0000")) {
    decoded_str = decoded_str.slice(0, decoded_str.length - 1)
  }
  return removeNullBytes(decoded_str);
}



export function decode_Collection(collection_record) {
  const collection = {};
  collection.owner = collection_record.owner;
  collection.id = collection_record.id;
  collection.hex_id = collection.id.collection_number.toString(16).padStart(32, '0');

  collection.data = decode_CollectionData(collection_record.data);
  return collection;
}

export function decode_CollectionData(collection_data_struct) {
  return collection_data_struct;
}

export function encode_TokenId(token_id) {
  return (
    `{token_number:${token_id.token_number.toString()}u128,`
    + `collection_number:${token_id.collection_number.toString()}u128}`
  );
}

export function decode_CollectionPublicData(collection_details_struct) {
  const collection_details = { ...collection_details_struct };
  collection_details.base_uri = aleo_string_obj_to_js_string(collection_details.base_uri);
  collection_details.metadata_uri = aleo_string_obj_to_js_string(collection_details.metadata_uri);

  return collection_details;
}


export function decode_Token(token_record) {
  const token = {};
  token.owner = token_record.owner;
  token.id = token_record.id;

  const token_data = {};
  token.hex_id = token.id.collection_number.toString(16).padStart(32, '0') + token.id.token_number.toString(16).padStart(32, '0');
  token_data.transferable = token_record.data.transferable;
  token_data._metadata_uri = token_record.data.metadata_uri;
  token_data.metadata_uri = aleo_string_obj_to_js_string(token_record.data.metadata_uri);
  token.data = token_data;
  return token;
}


export function decode_PrivacyPride(privacy_pride_record) {
  const privacy_pride = {};
  privacy_pride.owner = privacy_pride_record.owner;

  const data = aleo_string_obj_to_js_string(privacy_pride_record.data);

  const rdata = data.split("").reverse().join("");
  const part_length = 16;
  const part_amount = Math.ceil(rdata.length / part_length);
  let name = "";
  for (let i = 0; i < part_amount; i++) {
    let beg = rdata.length - part_length * (i + 1);
    let end = rdata.length - part_length * i;
    beg = Math.max(0, beg);
    end = Math.min(rdata.length, end)
    name += rdata.slice(beg, end)
  }
  privacy_pride.data = name;

  privacy_pride.edition = privacy_pride_record.edition;
  return privacy_pride;
}


export function decode_Proof(proof_record) {
  const proof = {};
  proof.object_type = proof_record.id?.token_number !== undefined ? "token" : "collection";
  proof.proof_type = proof_record?.is_holder !== undefined ? "holdership" : "ownership";
  proof.height = proof_record.height;
  proof.prover = proof_record.prover;
  proof.object_hex_id = proof.object_type === "token" ? (
    proof_record.id.collection_number.toString(16).padStart(32, '0')
    + proof_record.id.token_number.toString(16).padStart(32, '0')
  ) : (
    proof_record.id.collection_number.toString(16).padStart(32, '0')
  );

  proof.owner = proof_record.owner;
  proof.id = proof_record.id;

  return proof;
}


export function decode_Leos(leos_record) {
  const leos = {};
  leos.owner = leos_record.owner;
  leos.id = leos_record.id;
  leos.amount = leos_record.amount;

  return leos;
}



export function decode_MintData(mint_data) {
  return { ...mint_data };
}


export function BigNumToString(bn) {
  let hex = bn.toString(16).padStart(32, '0');
  if (hex.length % 2) { hex = '0' + hex; }

  const bi = BigInt('0x' + hex);

  return bi.toString(10);
}


export function encode_TokenData(token_data) {
  return `{\
    metadata_uri: ${big_ints_to_aleo_string_struct(Object.values(token_data._metadata_uri), 128)},\
    transferable: ${token_data.transferable}\
  }`;
};



export function token_hex_to_token_id(token_hex_id) {
  const collection_number_hex = token_hex_id.slice(0, 32);
  const token_number_hex = token_hex_id.slice(32, token_hex_id.length);
  return {
    collection_number: BigInt(`0x${collection_number_hex}`),
    token_number: BigInt(`0x${token_number_hex}`)
  }
}


export function encode_CollectionPublicData({
  royalty_fees,
  royalty_address,
  metadata_uri,
  base_uri,
  publicizable
}) {
  return `{\
    royalty_fees: ${String(royalty_fees)}u64,\
    royalty_address: ${royalty_address},\
    metadata_uri: ${js_string_to_aleo_string(metadata_uri, 4, 128)},\
    base_uri: ${js_string_to_aleo_string(base_uri, 4, 128)},\
    publicizable: ${String(publicizable)}\
  }`;
}


export function encode_MintData({
  whitelist,
  price,
  treasury,
  start,
  end,
  random
}) {
  return `{\
    whitelist: ${String(whitelist)},\
    price: ${String(price)}u64,\
    treasury: ${treasury},\
    start: ${String(start)}u32,\
    end: ${String(end)}u32,\
    random: ${String(random)}\
  }`;
}


export function encode_CollectionData(updatable) {
  return `{\
    updatable: ${String(updatable)}\
  }`;
}

export function encode_Listing({
  seller,
  price,
}) {
  return `{\
    seller: ${seller},\
    price: ${price}u64\
  }`;
}

export function encode_IndexCollectionMintId({
  index,
  collection_number,
  mint_number,
}) {
  return `{\
    index: ${index}u128,\
    collection_number: ${collection_number}u128,\
    mint_number: ${mint_number}u128\
  }`;
}


export function encode_CollectionId(collection_id) {
  return `{\
    collection_number: ${collection_id.collection_number.toString()}u128\
  }`.replace(/\s/g, "");
}


export function leo_record_to_js_object(leo_record) {
  const out = {};
  out._original_record = leo_record;
  out._original_record._str = JSON.stringify(leo_record);
  out.owner = leo_record.owner;
  out.data = leo_struct_to_js_object(leo_record.data);
  out.data.owner = leo_record.owner;
  return out;
}


export function leo_struct_to_js_object(struct) {
  const out = {};
  for (const key in struct) {
    if ((typeof struct[key]) === "object")
      out[key] = leo_struct_to_js_object(struct[key]);
    else
      out[key] = leo_object_to_js(struct[key]);
  }
  return out;
}


export function leo_records_to_js_object(leo_records) {
  return leo_records.map(leo_record_to_js_object);
}


function leo_object_to_js(leo_obj) {
  const inpt = leo_obj.split('.')[0];
  const intPattern = /(\-*\d+)(i|u)(\d+)/;

  const matches_int = inpt.match(intPattern);
  if (matches_int) {
    const [, number1, type, number2] = matches_int;
    return BigInt(number1, 10);
  }
  if (inpt === "true") return true
  if (inpt === "false") return false

  return inpt
}
