import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { burnModalHide } from "../../redux/counterSlice";
import { useAlert } from "react-alert";
import { burn_private, burn_public } from "../../lib/client/aleo";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useRouter } from 'next/router';
import Image from "next/image";

const loader_img = "/images/loading.gif";


async function burnToken(
  burn_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  close_modal,
  loading,
  setLoading,
  checked,
  setChecked
) {
  if (loading || !publicKey || !requestRecords || !requestTransaction)
    return
  setLoading(true);
  try {
    if (!checked)
      throw new Error("Please check the box to confirm that you understand this action is irreversible.");

    const to_call = burn_data.is_private ? burn_private : burn_public;
    const res = await to_call(
      publicKey,
      requestRecords,
      requestTransaction,
      burn_data,
    );
    router.push("/transaction/status/leo/" + res);
    setChecked(false);
    close_modal()
    return true;
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }
  setLoading(false);
}

const BurnModal = () => {
  const { burnModal, burn_data } = useSelector((state) => state.counter); //
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const router = useRouter();
  const {
    publicKey,
    requestRecords,
    requestTransaction
  } = useWallet();

  const close_modal = () => {
    setChecked(false);
    dispatch(burnModalHide())
  };

  return (
    <div>
      <div className={burnModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Burn NFT
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={close_modal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {/*
              <div className="text-right">
                <span className="dark:text-jacarta-400 text-sm">
                  Balance: 0.0000 WETH
                </span>
  </div>*/}
              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="irreversible-check2"
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />
                <label
                  htmlFor="irreversible-check2"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I understand that this action is irreversible.
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={
                    () => burnToken(
                      burn_data,
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      close_modal,
                      loading,
                      setLoading,
                      checked,
                      setChecked
                    )}
                >
                  {
                    loading
                      ? <Image src={loader_img} width={25} height={25} />
                      : "Burn NFT"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurnModal;
