import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateWhitelistModalHide } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useAlert } from "react-alert";
import {
  set_collection_mint,
  is_valid_aleo_address,
  update_whitelist
} from "lib/client/aleo";
import { useRouter } from "next/router";


import Image from "next/image";

const loader_img = "/images/loading.gif";


async function updateWhitelist(
  update_whitelist_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  close_modal,
  loading,
  setLoading
) {
  if (loading || !publicKey || !requestRecords || !requestTransaction)
    return
  setLoading(true);
  try {
    throw_invalid_update_whitelist_data(update_whitelist_data);
    console.log({ update_whitelist_data });

    const res = await update_whitelist(
      publicKey,
      requestRecords,
      requestTransaction,
      {
        address: update_whitelist_data.whitelistAddress,
        amount: update_whitelist_data.whitelistAmount,
        collection: update_whitelist_data.collection,
        mint_number: update_whitelist_data.collectionMint.id.mint_number
      }
    );
    router.push("/transaction/status/leo/" + res);
    document.getElementById("irreversible-check").checked = false;
    close_modal()
    return true;
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }
  setLoading(false);
}

function throw_invalid_update_whitelist_data(update_whitelist_data) {
  if (update_whitelist_data.whitelistAmount === "" || update_whitelist_data.whitelistAmount === undefined) {
    throw new Error("Invalid whitelist amount.");
  }
  const wl_amount = parseInt(update_whitelist_data.whitelistAmount);
  update_whitelist_data.whitelistAmount = wl_amount;
  if (wl_amount < 0 || isNaN(wl_amount))
    throw new Error("Whitelist amount should be a positive integer.");

  if (!is_valid_aleo_address(update_whitelist_data.whitelistAddress))
    throw new Error("Invalid treasury address.");

  return update_whitelist_data;
}


const UpdateWhitelistModal = () => {
  const { updateWhitelistModal, update_whitelist_data } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { publicKey, requestRecords, requestTransaction } = useWallet();
  const alert = useAlert();
  const router = useRouter();

  const [whitelistAmount, setWhitelistAmount] = useState("");
  const [whitelistAddress, setWhitelistAddress] = useState("");

  const handlewhitelistAmount = (e) => {
    e.preventDefault();
    setWhitelistAmount(e.target.value);
  };

  useEffect(() => {
    if (update_whitelist_data !== undefined) {
      if (update_whitelist_data.whitelistAmount !== undefined)
        setWhitelistAmount(update_whitelist_data.whitelistAmount);
      if (update_whitelist_data.whitelistAddress !== undefined)
        setWhitelistAddress(update_whitelist_data.whitelistAddress);
    }
  }, [update_whitelist_data]);

  const close_modal = () => {
    dispatch(updateWhitelistModalHide());
    setWhitelistAmount("");
    setWhitelistAddress("");
  };

  return (
    <div>
      <div className={updateWhitelistModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Update Whitelist
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => close_modal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {/*
                <div className="text-right">
                  <span className="dark:text-jacarta-400 text-sm">
                    Balance: 0.0000 WETH
                  </span>
                </div>
              */}
              <div className="mb-6">
                <label
                  htmlFor="whitelist-address"
                  className="font-display text-jacarta-700 text-sm font-semibold dark:text-white normal-case"
                >
                  Address <span className="text-red">*</span>
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="url"

                    id="whitelist-address"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white flex-grow"
                    placeholder="aleo1..."
                    value={whitelistAddress}
                    style={{
                      cursor: update_whitelist_data.addressFixed ? "not-allowed" : null,
                    }}
                    onChange={(e) => {
                      if (update_whitelist_data.addressFixed)
                        return;
                      setWhitelistAddress(e.target.value)
                    }
                    }
                  />
                  {!update_whitelist_data.addressFixed && (<button
                    onClick={
                      () => {
                        setWhitelistAddress(publicKey)
                      }
                    }
                    className="group dark:bg-jacarta-700 hover:bg-accent border-accent flex h-12 w-12 shrink-0 items-center justify-center rounded-lg border bg-white hover:border-transparent">
                    <span className="text-accent text-2xs font-semibold group-hover:text-white">
                      Use mine
                    </span>
                  </button>)}
                </div>
              </div>


              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white normal-case">
                  Whitelist spots <span className="text-red">*</span>
                </span>
              </div>
              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                <input
                  type="number"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="Amount"
                  value={whitelistAmount}
                  onChange={(e) => {
                    if (!/[0-9]/.test(e.key))
                      e.preventDefault();
                    handlewhitelistAmount(e)
                  }
                  }
                />
              </div>

              {/* <!-- Terms --> */}

            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={
                    () => updateWhitelist(
                      {
                        whitelistAmount,
                        whitelistAddress,
                        collectionMint: update_whitelist_data.collectionMint,
                        collection: update_whitelist_data.collection
                      },
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      close_modal,
                      loading,
                      setLoading
                    )}
                >
                  {
                    loading
                      ? <Image src={loader_img} width={25} height={25} />
                      : "Set Whitelist"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateWhitelistModal;
