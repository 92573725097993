
import {
  encode_CollectionMetadata,
  encode_unsigned_int,
  encode_bool,
  random_uint,
  decode_Collection,
  js_string_to_aleo_string,
  snarkos_records_to_js_object,
  leo_records_to_js_object,
  decode_Token,
  encode_TokenData,
  encode_CollectionPublicData,
  encode_TokenId,
  decode_Leos,
  decode_Proof,
  encode_Listing,
  encode_IndexCollectionMintId,
  decode_PrivacyPride,
  encode_CollectionData,
  js_string_to_usize,
  encode_MintData
} from './types'

import {
  Transaction,
  Transition,
  WalletAdapterNetwork,
  WalletNotConnectedError
} from "@demox-labs/aleo-wallet-adapter-base";


export async function create_collection(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    updatable,
    details
  }
) {
  const inputs = [
    encode_unsigned_int(random_uint(128), 128),
    encode_CollectionData(updatable),
    encode_CollectionPublicData(details),
  ];

  const fee = 1_256_238;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'create_collection',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function update_collection_public_data(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    collection_public_data
  }
) {
  const inputs = [
    collection,
    encode_CollectionPublicData(collection_public_data),
  ];

  const fee = 1_504_699;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'update_collection_public_data',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function mint_private(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    receiver,
    metadata_uri,
    transferable
  }
) {
  const inputs = [
    collection,
    encode_unsigned_int(random_uint(128), 128),
    receiver,
    `{\
        metadata_uri:${js_string_to_aleo_string(metadata_uri, 4, 128)},\
        transferable:${String(transferable)}\
    }`,
  ];
  const fee = 1_256_746;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'mint_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}




export async function create_token_mint(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    metadata_uri,
    transferable,
    mint_number
  }
) {
  const inputs = [
    collection,
    encode_unsigned_int(random_uint(128), 128),
    String(mint_number) + 'u128',
    `{\
        metadata_uri:${js_string_to_aleo_string(metadata_uri, 4, 128)},\
        transferable:${String(transferable)}\
    }`,
  ];
  const fee = 3_508_445;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'create_token_mint',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



export async function remove_token_mint(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    token_number,
    mint_number,
    index
  }
) {
  const inputs = [
    collection,
    String(token_number) + 'u128',
    String(mint_number) + 'u128',
    String(index) + 'u128',
  ];

  console.log(inputs)

  const fee = 3_540_199;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'remove_token_mint',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



export async function get_user_leos(
  publicKey,
  requestRecords,
  records
) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_CREDITS_PROGRAM_ID);
  /*
  const records = await requestRecords(process.env.NEXT_PUBLIC_NFT_PROGRAM_ID);
  const leo_records = records.filter(record => (
    !record.spent
    && record.data.owner === publicKey
    && record?.data?.id?.token_number !== undefined
  ));
  */
  const snarkos_records = [];
  const token_records = snarkos_records_to_js_object(snarkos_records)

  return token_records.map(
    leos_record => {
      const leos = decode_Leos(leos_record);
      leos._record = leos_record._original_record;
      return leos;
    }
  );
}


export async function get_user_proofs(
  publicKey,
  requestRecords,
  records
) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_NFT_PROGRAM_ID);
  records = records.filter(record => (
    !record.spent
    && record.owner === publicKey
    && record?.data?.id?.collection_number !== undefined
    && (
      record?.data?.is_holder !== undefined
      || record?.data?.is_owner !== undefined
    )
    && record?.data?.height !== undefined
  ));

  const proof_records = leo_records_to_js_object(records);

  return proof_records.map(
    proof_record => {
      const proof = decode_Proof(proof_record.data);
      proof._record = proof_record._original_record;
      return proof;
    }
  );
}


export async function get_user_private_tokens(
  publicKey,
  requestRecords,
  records
) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_NFT_PROGRAM_ID);
  records = records.filter(record => (
    !record.spent
    && record.owner === publicKey
    && record?.data?.id?.collection_number !== undefined
    && record?.data?.id?.token_number !== undefined
    && record?.data?.data?.transferable !== undefined
    && record?.data?.data?.metadata_uri !== undefined
  ));

  const token_records = leo_records_to_js_object(records);
  console.log({ token_records })
  return token_records.map(
    token_record => {
      const token = decode_Token(token_record.data);
      token._record = token_record._original_record;
      return token;
    }
  );
}



export async function get_user_privacy_prides(
  publicKey,
  requestRecords,
  records
) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_PRIVACY_PRIDE_PROGRAM_ID);
  records = records.filter(record => (
    !record.spent
    && record.owner === publicKey
    && record?.data?.data !== undefined
    && record?.data?.edition !== undefined
  ));

  const privacy_pride_records = leo_records_to_js_object(records);

  return privacy_pride_records.map(
    privacy_pride_record => {
      const privacy_pride = decode_PrivacyPride(privacy_pride_record.data);
      privacy_pride._record = privacy_pride_record._original_record;
      return privacy_pride;
    }
  );
}


export async function burn_private(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    token
  }
) {
  const inputs = [
    collection,
    token
  ];

  const fee = 5_000_000; // This will fail if fee is not set high enough

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'burn_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function burn_public(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    token
  }
) {
  const inputs = [
    collection,
    encode_TokenId(token)
  ];

  const fee = 3_018_271; // This will fail if fee is not set high enough

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'burn_public',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function prove_collection_ownership(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    address,
    height
  }
) {
  const inputs = [
    collection,
    address,
    `${height}u32`
  ];

  const fee = 16_448; // This will fail if fee is not set high enough

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'prove_collection_ownership',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function transfer_collection(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    address,
  }
) {
  const inputs = [
    collection,
    address,
  ];

  const fee = 2_019; // This will fail if fee is not set high enough

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_collection',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



export async function prove_token_ownership(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    token,
    address,
    height
  }
) {
  const inputs = [
    token,
    address,
    `${height}u32`
  ];

  const fee = 16_635;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'prove_token_ownership',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function prove_collection_holdership(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    token,
    address,
    height
  }
) {
  const inputs = [
    token,
    address,
    `${height}u32`
  ];

  console.log(inputs);

  const fee = 16_610;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'prove_collection_holdership',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function get_user_collections(
  publicKey,
  requestRecords,
  records
) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_NFT_PROGRAM_ID);
  records = records.filter(record => (
    !record.spent
    && record.owner === publicKey
    && record?.data?.id?.collection_number !== undefined
    && record?.data?.id?.token_number === undefined
    && record?.data?.data?.updatable !== undefined
  ));

  const collection_records = leo_records_to_js_object(records);

  return collection_records.map(
    collection_record => {
      const collection = decode_Collection(collection_record.data);
      collection._record = collection_record._original_record;
      return collection;
    }
  );
}


export async function get_user_credits(
  publicKey,
  requestRecords
) {
  const records = await requestRecords(process.env.NEXT_PUBLIC_CREDITS_PROGRAM_ID);
  const token_records = records.filter(record => (
    !record.spent
  ));
  return token_records;
}


export async function get_credits_for_amount(
  publicKey,
  requestRecords,
  amount
) {
  const records = await get_user_credits(
    publicKey,
    requestRecords
  );
  let out_rec = null;
  let out_rec_amount = null;
  for (const record of records) {
    const rec_amount = Number(record.data.microcredits.split('.')[0].split('u')[0]);
    if (amount <= rec_amount && (out_rec === null || rec_amount < out_rec_amount)) {
      out_rec = record;
      out_rec_amount = rec_amount;
    }
  }
  if (out_rec === null)
    throw new Error('No record with enough credits to buy the listing.');
  return out_rec;
}

const record_to_microcredits = (record) => Number(
  record.data.microcredits.split('.')[0].split('u')[0]
);

export async function get_credits_and_fee_for_amount(
  publicKey,
  requestRecords,
  amount,
  fee_amount_min
) {
  const records = await get_user_credits(
    publicKey,
    requestRecords
  );
  const sorted_records = records.sort(
    (a, b) => (
      record_to_microcredits(a) - record_to_microcredits(b)
    )
  );
  console.log(amount);

  let fee_i = null;

  for (let i = 0; i < sorted_records.length; i++) {
    if (record_to_microcredits(sorted_records[i]) >= fee_amount_min) {
      fee_i = i;
      break;
    }
  }
  if (fee_i === null)
    throw new Error('No record with enough credits to buy the listing.');

  const fee = sorted_records[fee_i];

  let payment_i = null;

  for (let i = 0; i < sorted_records.length; i++) {
    if (record_to_microcredits(sorted_records[i]) >= amount && i !== fee_i) {
      payment_i = i;
      break;
    }
  }
  if (payment_i === null)
    throw new Error('No record with enough credits to buy the listing.');

  const payment = sorted_records[payment_i];

  return {
    payment,
    fee
  };
}


export async function transfer_token(
  publicKey,
  requestRecords,
  requestTransaction,
  { token, to_privacy, receiver }
) {
  const is_private = token._record !== undefined;
  if (is_private && to_privacy) {
    return await transfer_private(publicKey, requestRecords, requestTransaction, token, receiver);
  }
  else if (is_private && !to_privacy) {
    return await transfer_private_to_public(publicKey, requestRecords, requestTransaction, token, receiver);
  }
  else if (!is_private && to_privacy) {
    return await transfer_public_to_private(publicKey, requestRecords, requestTransaction, token, receiver);
  }
  else {
    return await transfer_public(publicKey, requestRecords, requestTransaction, token, receiver);
  }
}


export async function transfer_private(publicKey, requestRecords, requestTransaction, token, receiver) {
  const inputs = [
    token._record,
    receiver,
  ];

  const fee = 2_181;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_token_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function freeze_collection_updates(publicKey, requestRecords, requestTransaction, collection) {
  const inputs = [
    collection
  ];

  const fee = 3_967;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'freeze_collection_updates',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


freeze_collection_updates


export async function pp_transfer_private(publicKey, requestRecords, requestTransaction, token, receiver) {
  const inputs = [
    token,
    receiver,
  ];

  const fee = 1_000_000;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_PRIVACY_PRIDE_PROGRAM_ID,
    'transfer_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



async function transfer_public(publicKey, requestRecords, requestTransaction, token, receiver) {
  const inputs = [
    `{token_number:${token.id.token_number.toString()}u128,collection_number:${token.id.collection_number.toString()}u128}`,
    receiver
  ];

  const fee = 2_005_415;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_token_public',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


async function transfer_private_to_public(publicKey, requestRecords, requestTransaction, token, receiver) {
  const inputs = [
    token._record,
    receiver
  ];

  const fee = 2_506_008;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_t_private_to_public',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}

async function transfer_public_to_private(publicKey, requestRecords, requestTransaction, token, receiver) {
  const inputs = [
    encode_TokenId(token.id),
    encode_TokenData(token.data),
    receiver
  ];

  const fee = 1_026_121;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_t_public_to_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function transfer_credits_private(publicKey, requestRecords, requestTransaction, receiver, amount, records) {
  if (records === undefined)
    records = await requestRecords(process.env.NEXT_PUBLIC_CREDITS_PROGRAM_ID);
  records = records.filter(record => !record.spent);
  let out_rec = null;
  for (const record of records) {
    const rec_amount = Number(record.data.microcredits.split('.')[0].split('u')[0]);
    if (amount <= rec_amount) {
      out_rec = record;
      break;
    }
  }
  if (out_rec === null)
    throw new Error('No record with enough credits to make the transfer');

  const inputs = [
    out_rec,
    receiver,
    `${amount}u64`,
  ];

  const fee = process.env.NEXT_PUBLIC_TRANSFER_CREDITS_FEE;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_CREDITS_PROGRAM_ID,
    'transfer_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function transfer_leos(publicKey, requestRecords, requestTransaction, leosRecord, amount, receiver) {
  const inputs = [
    leosRecord._record,
    `${amount}u64`,
    receiver
  ];

  const fee = 1_000_000;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'transfer_leos',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function create_listing(publicKey, requestRecords, requestTransaction, token_id, price) {
  const inputs = [
    encode_TokenId(token_id),
    `${price}u64`,
  ];

  const fee = 2_017_360;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'create_listing',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function update_listing(publicKey, requestRecords, requestTransaction, token_id, price) {
  const inputs = [
    encode_TokenId(token_id),
    `${price}u64`,
  ];

  const fee = 1_505_360;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'update_listing',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function accept_listing(
  publicKey, requestRecords, requestTransaction, {
    listing: {
      seller,
      price,
    },
    royalty_fees,
    royalty_address,
    token_id,
    token_data: {
      _metadata_uri,
      transferable,
    }
  }
) {
  const fee_amount = 1_536_431;

  const { payment, fee } = await get_credits_and_fee_for_amount(
    publicKey,
    requestRecords,
    Number(price),
    fee_amount
  );
  const inputs = [
    encode_Listing({
      seller,
      price,
    }),
    payment,
    `${royalty_fees}u64`,
    royalty_address,
    encode_TokenId(token_id),
    encode_TokenData({
      _metadata_uri,
      transferable,
    }),
  ];
  console.log(inputs);

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'accept_listing',
    inputs,
    fee_amount
  );


  return await requestTransaction(aleoTransaction);
}


export async function mint_public(
  publicKey, requestRecords, requestTransaction, {
    index_mint_id: {
      index,
      collection_number,
      mint_number,
    },
    token_number,
    treasury,
    price
  }
) {
  const fee_amount = 9_831_457;

  const { payment, fee } = await get_credits_and_fee_for_amount(
    publicKey,
    requestRecords,
    Number(price),
    fee_amount
  );
  const inputs = [
    encode_IndexCollectionMintId({
      index,
      collection_number,
      mint_number,
    }),
    `${token_number}u128`,
    payment,
    treasury,
    `${price}u64`,
  ];
  console.log(inputs);

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'mint_public',
    inputs,
    fee_amount
  );


  return await requestTransaction(aleoTransaction);
}


export async function cancel_listing(publicKey, requestRecords, requestTransaction, token_id) {
  const inputs = [
    encode_TokenId(token_id)
  ];

  const fee = 1_513_303;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'cancel_listing',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



export async function update_metadata_public(publicKey, requestRecords, requestTransaction, update) {
  const inputs = [
    update.collection,
    encode_TokenId(update.token_id),
    `{\
      metadata_uri:${js_string_to_aleo_string(update.metadata_uri, 4, 128)},\
      transferable:${String(update.transferable)}\
    }`,
  ];

  const fee = 1_002_437;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'update_token_data_public',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function update_metadata_private(publicKey, requestRecords, requestTransaction, update) {
  const inputs = [
    update.collection,
    update.token,
    `{\
      metadata_uri:${js_string_to_aleo_string(update.metadata_uri, 4, 128)},\
      transferable:${String(update.transferable)}\
    }`,
  ];

  const fee = 2_765;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'update_token_data_private',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}



export async function mint_alias(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    name,
    receiver,
    metadata_uri
  }
) {
  console.log({
    name: js_string_to_usize(name, 128)
  })
  const inputs = [
    `${js_string_to_usize(name, 128)}u128`,
    receiver,
    js_string_to_aleo_string(metadata_uri, 4, 128)
  ];
  console.log(inputs)
  const fee = 1_255_879;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'mint_alias',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function update_whitelist(
  publicKey,
  requestRecords,
  requestTransaction,
  {
    collection,
    mint_number,
    address,
    amount,
  }
) {
  const inputs = [
    collection._record,
    String(mint_number) + 'u128',
    address,
    String(amount) + 'u64',
  ];
  const fee = 1_002_212;

  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'update_whitelist',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


export async function set_collection_mint(publicKey, requestRecords, requestTransaction, create_mint_data) {
  const inputs = [
    create_mint_data.collection,
    encode_unsigned_int(random_uint(128), 128),
    encode_MintData({
      whitelist: create_mint_data.whitelist,
      price: create_mint_data.creditsAmount,
      treasury: create_mint_data.treasuryAddress,
      start: create_mint_data.startBlock,
      end: create_mint_data.endBlock,
      random: create_mint_data.random
    }),
  ];

  const fee = 1_506_330
  const aleoTransaction = Transaction.createTransaction(
    publicKey,
    WalletAdapterNetwork.Testnet,
    process.env.NEXT_PUBLIC_NFT_PROGRAM_ID,
    'set_collection_mint',
    inputs,
    fee
  );
  return await requestTransaction(aleoTransaction);
}


