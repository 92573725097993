import Head from "next/head";

const Meta = ({ title, keyword, desc, cover }) => {
  const website_title = `Aleo.store | ${title}`;
  return (
    <div>
      <Head>
        <title>{website_title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        {
          cover && (
            <>
              <meta property="og:image:alt" content="Cover" />
              <meta property="og:image:type" content="image/png" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta name="twitter:image" content={cover} />
              <meta name="twitter:image:type" content="image/png" />
              <meta name="twitter:image:width" content="1200" />
              <meta name="twitter:image:height" content="630" />

              <meta property="og:title" content={website_title} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://aleo.store" />
              <meta property="og:image" content={cover} />
              <meta property="og:description" content={desc} />
              <meta name="theme-color" content="#FFFFFF" />
              <meta name="twitter:card" content="summary_large_image" />

            </>
          )
        }

      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "NFT Marketplace",
  keyword:
    "aleo, nft, zero knowledge proof, blockchain, crypto, crypto collectibles, crypto marketplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT, non-fungible tokens, virtual asset, wallet",
  desc: "The First NFT Marketplace On Aleo: Create, Buy, And Sell NFTs Anonymously. Keep Your Data Private And Provide Zero Knowledge Proofs.",
};

export default Meta;
