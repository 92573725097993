import { useDispatch } from "react-redux";
import { walletModalShow } from "../../redux/counterSlice";

import { useRouter } from "next/router";

import { WalletNotConnectedError, WalletAdapter } from "@demox-labs/aleo-wallet-adapter-base";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { LeoWalletAdapter } from "@demox-labs/aleo-wallet-adapter-leo";
import { WalletConnectButton, WalletModal, WalletMultiButton, useWalletModal } from "@demox-labs/aleo-wallet-adapter-reactui"
import { useEffect } from "react";


export default function WalletButton() {
  const dispath = useDispatch();
  const router = useRouter();
  //const { status, connect, account, chainId, ethereum } = useMetaMask();
  const wal = useWallet();
  const modal = useWalletModal();

  useEffect(() => {
    var dropLists = document.getElementsByClassName("wallet-adapter-dropdown-list");
    if (dropLists.length === 0)
      return;
    var dropList = dropLists[0];
    // check droplist children amount less than 4
    if (dropList.childNodes.length > 3)
      return;


    // Add a li in second position of dropdown list
    var li = document.createElement("li");
    li.innerHTML = "Profile";
    li.className = "wallet-adapter-dropdown-list-item";
    li.onclick = () => {
      router.push("/user/" + wal.publicKey, null, { shallow: true });
    }
    dropList.insertBefore(li, dropList.childNodes[0]);
    // is dom updated?
  }, [wal]);


  wal.style = {
    whiteSpace: "nowrap",
    backgroundColor: "rgb(131 88 255)",
    fontSize: "14px",
  }

  if (true || wal.connected) {
    wal.style = {
      ...wal.style,
      color: "black",
      backgroundColor: "rgb(255 255 255)",
      borderColor: "rgb(231 232 236)",
      borderWidth: "1px",
      borderRadius: "9999px",
      borderStyle: "solid",
    }
  }

  return (
    <>
      <WalletMultiButton
        {...wal}
      />    {/*
      {modal.visible && <WalletModal
        {...modal}
      />}
      <WalletConnectButton
        {...wal}
      />*/}
    </>
  );

  const status = "notConnected";

  if (status === "initializing") return <div>Ongoing...</div>;

  if (status === "unavailable")
    return (
      <button
        onClick={walletHandler}
        className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
        </svg>
      </button>
    );

  if (status === "notConnected")
    return (
      <button
        onClick={connect}
        className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
        </svg>
      </button>
    );

  if (status === "connecting") return <div>Connecting...</div>;

  return null;
}
