import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mintLeosModalHide } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useAlert } from "react-alert";
import { pp_transfer_private } from "lib/client/aleo";
import { useRouter } from "next/router";

import Image from "next/image";


const loader_img = "/images/loading.gif";



async function mintLeos(
  mint_leos_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  close_modal,
  loading,
  setLoading
) {
  if (loading || !publicKey || !requestRecords || !requestTransaction)
    return
  setLoading(true);
  try {

    if (!document.getElementById("irreversible-check").checked)
      throw new Error("Please check the box to confirm that you understand wrapping.");
    const response = await fetch(
      `/api/v0/leos/mint/${publicKey}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (data?.error)
      throw new Error(data.error);

    const res = await pp_transfer_private(
      publicKey,
      requestRecords,
      requestTransaction,
      mint_leos_data.record,
      data.address
    );
    router.push("/transaction/status/leo/" + res);
    document.getElementById("irreversible-check").checked = false;
    close_modal()
    return true;
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }
  setLoading(false);
}



const MintLeosModal = () => {
  const { mintLeosModal, mint_leos_data } = useSelector((state) => state.counter); //
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { publicKey, requestRecords, requestTransaction } = useWallet();
  const alert = useAlert();
  const router = useRouter();

  const close_modal = () => (dispatch(mintLeosModalHide()));

  return (
    <div>
      <div className={mintLeosModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Import external NFT
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(mintLeosModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {/*
        <div className="text-right">
          <span className="dark:text-jacarta-400 text-sm">
            Balance: 0.0000 WETH
          </span>
</div>*/}
              <div className="text-justify">
                <span className="dark:text-jacarta-400 text-sm normal-case text-justify">
                  To import former NFTs minted outside of ARC721 token standard and exchange it on compatible marketplaces, you need to wrap your token first.
                  <strong className="normal-case"> Process is centralised</strong>. In exchange for your Privacy Pride NFT you will receive a wrapped token. This, while not ideal, allows
                  backward compatibility with former NFTs.
                  <br /><br />
                  <strong className="normal-case">You can swap NFTs back at any time.</strong>
                </span>
              </div>
              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="irreversible-check"
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />
                <label
                  htmlFor="irreversible-check"
                  className="dark:text-jacarta-200 text-sm normal-case"
                >
                  I understand token wrapping
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={
                    () => mintLeos(
                      mint_leos_data,
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      close_modal,
                      loading,
                      setLoading
                    )}
                >
                  {
                    loading
                      ? <Image src={loader_img} width={25} height={25} />
                      : "Wrap NFT"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintLeosModal;
