import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const footer = () => {
    return (
        <>
            {/* <!-- Footer --> */}

            <footer className="dark:bg-jacarta-900 page-footer bg-white">
                <div className="container">
                    <div className="flex flex-col items-center justify-between space-y-2 sm:flex-row sm:space-y-0" style={{ flexFlow: "row-reverse" }} >
                        <span className="dark:text-jacarta-400 text-sm">
                            <span>© {new Date().getFullYear()} Aleo.store — Made by <a href="https://www.bandersnatch.io/"><b>Bandersnatch</b></a></span>
                        </span>
                    </div>

                </div>
            </footer >
        </>
    );
};

export default footer;
