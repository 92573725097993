import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { transferCollectionModalHide } from "../../redux/counterSlice";
import { useAlert } from "react-alert";
import { transfer_collection } from "../../lib/client/aleo";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { useRouter } from 'next/router';



async function transferCollection(
  transfer_collection_data,
  publicKey,
  requestRecords,
  requestTransaction,
  alert,
  router,
  address,
  setAddress,
  close_modal
) {
  try {
    if (!publicKey || !requestRecords || !requestTransaction)
      throw new Error("Wallet not connected");

    const res = await transfer_collection(
      publicKey,
      requestRecords,
      requestTransaction,
      {
        collection: transfer_collection_data.collection,
        address
      }
    );

    router.push("/transaction/status/leo/" + res);
    setAddress("");
    close_modal()
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }

}


const TransferCollectionModal = () => {
  const { transferCollectionModal, transfer_collection_data } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const alert = useAlert();
  const router = useRouter();
  const {
    publicKey,
    requestRecords,
    requestTransaction
  } = useWallet();

  const [address, setAddress] = useState("");

  const handleAddress = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
  };
  const close_modal = () => {
    dispatch(transferCollectionModalHide())
    setAddress("");
  };
  return (
    <div>
      <div className={transferCollectionModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Transfer to
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={close_modal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Address <span className="text-red">*</span>
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">

                <input
                  type="text"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="aleo1..."
                  value={address}
                  onChange={(e) => handleAddress(e)}
                />

              </div>

            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={() =>
                    transferCollection(
                      transfer_collection_data,
                      publicKey,
                      requestRecords,
                      requestTransaction,
                      alert,
                      router,
                      address,
                      setAddress,
                      close_modal
                    )
                  }
                >
                  Transfer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TransferCollectionModal;
